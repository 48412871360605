export const GET_ALL_ALLOTMENTS = 'GET_ALL_ALLOTMENTS'
export const GET_GROUPED_ALLOTMENTS = 'GET_GROUPED_ALLOTMENTS'
export const GET_ALLOTMENT_DETAILS = 'GET_ALLOTMENT_DETAILS'

export interface Additional {
  _id: string;
  cost: number;
  min: number;
  max: number;
}

export interface Data {
  additionals: Additional[];
  bookings: string[];
  createdAt: string;
  date: string;
  hex_color: string;
  minimumStay: number;
  maximumStay: number;
  merchantId: string;
  product_id: string;
  product_name: string;
  price: number;
  rate_plan: string;
  rate_plan_abbr: string;
  rate_plan_name: string;
  status: string;
  stock: number;
  updatedAt: string;
  __v: number;
  _id: string;
}

export interface Allotments {
  _id: string;
  data: Data[];
}

export interface RatePlan {
  _id: string;
  abbrv: string;
  hex_color: string;
  name: string;
  product: string;
}

export interface GroupedAllotments {
  rate_plan: RatePlan;
  start: string;
  end: string;
  status: string;
}

export interface Stock {
  isMultiple: boolean
  value: number | null
}

export interface Promo extends Stock {
  type: string | null
}

export interface Stay {
  isMultiple: boolean
  min: number | null
  max: number | null
}

export interface AllotmentsAdditional {
  _id: string;
  cost: Stock;
  min: Stock;
  max: Stock;
  additional_name: string;
}

export interface AllotmentDetail {
  availability: boolean
  stock: Stock
  price: Stock
  stay: Stay
  promo: Promo
  day: number
  additionals: AllotmentsAdditional[]
}

export interface GetAllAllotments {
  type: typeof GET_ALL_ALLOTMENTS
  payload: Allotments[]
}

export interface GetGroupedAllotments {
  type: typeof GET_GROUPED_ALLOTMENTS
  payload: GroupedAllotments[]
}

export interface GetAllotmentDetails {
  type: typeof GET_ALLOTMENT_DETAILS
  payload: AllotmentDetail[]
}

export interface AllotmentState {
  allAllotments: Allotments[]
  groupedAllotments: GroupedAllotments[]
  allotmentDetails: AllotmentDetail[]
}

export type AllotmentActionType =
  | GetAllAllotments
  | GetAllotmentDetails
  | GetGroupedAllotments