import {
  BookingActionType,
  BookingState,
  BOOKING_DATA_LOADED,
  BOOKING_DATA_LOADING,
  GET_AVAILABLE_ALLOTMENT,
  GET_BOOKING_DETAIL,
  GET_BOOKING_LIST,
  GET_MESSAGE_LISTENER
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 5,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: BookingState = {
  bookingList: defaultList,
  bookingDetail: null,
  availableAllotment: [],
  messageListener: {
    id: '',
    message: ''
  },
  isLoading: false
}

export default function (state= initialState, action: BookingActionType): BookingState {
  switch (action.type) {
    case GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload
      }
    case GET_BOOKING_DETAIL:
      return {
        ...state,
        bookingDetail: action.payload
      }
    case GET_AVAILABLE_ALLOTMENT:
      return {
        ...state,
        availableAllotment: action.payload
      }
    case GET_MESSAGE_LISTENER:
      return {
        ...state,
        messageListener: action.payload
      }
    case BOOKING_DATA_LOADING:
      return {
        ...state,
        isLoading: true
      }
    case BOOKING_DATA_LOADED:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}