import { Hero } from "../landingPage/types";

export const GET_MERCHANT_PROFILE = 'GET_MERCHANT_PROFILE'
export const GET_MERCHANT_LIST = 'GET_MERCHANT_LIST'
export const GET_MERCHANT_BANK_ACCOUNT = 'GET_MERCHANT_BANK_ACCOUNT'
export const GET_BANK_LIST = 'GET_BANK_LIST'

export interface AmountLimits {
  minimum: number;
  maximum: number;
  minimum_increment: number;
}

export interface ChannelProperties {
  account_number: string;
  account_holder_name: string;
}

export interface BankList {
  channel_code: string;
  channel_category: string;
  currency: string;
  channel_name: string;
  inquiry?: boolean;
  amount_limits: AmountLimits;
}

export interface BankAccount extends BankList {
  channel_properties: ChannelProperties;
}

export interface PrivacyPolicy {
  url: string;
}

export interface Contact {
  email: string;
  phone_number: string;
}

export interface Profile {
  title: string;
  desc: string;
  tagline: string;
  logo: Hero | null;
  hero: Hero | null;
  others?: {
    [names: string]: string;
  }
  rating_gmaps: number;
  total_rooms: number;
  socials: {
    [names: string]: string;
  }
  contact: Contact
  location: string;
  location_url: string;
  website_url: string;
  booking_engine_url: string;
  bank_accounts?: BankAccount[];
  terms_conditions: Hero | null;
  privacy_policy: PrivacyPolicy;
  email_notifications: string;
}

export interface MerchantList {
  _id: string;
  name: string;
  alias: string;
  icon: Hero;
}

export interface GetMerchantProfile {
  type: typeof GET_MERCHANT_PROFILE;
  payload: Profile;
}

export interface GetMerchantList {
  type: typeof GET_MERCHANT_LIST;
  payload: MerchantList[];
}

export interface GetMerchantBankAccountType {
  type: typeof GET_MERCHANT_BANK_ACCOUNT;
  payload: BankAccount[];
}

export interface GetBankList {
  type: typeof GET_BANK_LIST;
  payload: BankList[];
}

export interface MerchantState {
  profile: Profile,
  merchantList: MerchantList[],
  merchantBankAccount: BankAccount[],
  bankList: BankList[]
}

export type MerchantActionType =
  | GetMerchantProfile
  | GetMerchantList
  | GetMerchantBankAccountType
  | GetBankList