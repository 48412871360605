import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import {  Toaster } from "react-hot-toast";

const ConfirmResetPassword = React.lazy(() => import('./pages/Auth/ConfirmResetPassword'))
const Login = React.lazy(() => import('./pages/Auth/Login'))
const Register = React.lazy(() => import('./pages/Auth/Register'))
const ResetPassword = React.lazy(() => import('./pages/Auth/ResetPassword'))
const Verify = React.lazy(() => import('./pages/Auth/Verify'))

const PrivateRoute = React.lazy(() => import('./components/PrivateRoute'))
const Layout = React.lazy(() => import('./components/containers/Layout'))

function App() {
  return (
    <div className="">
      <Toaster
        position="top-right"
        reverseOrder={false}
        gutter={8}
        containerClassName=""
        containerStyle={{}}
        toastOptions={{
          // Define default options
          className: 'shadow-card text-sm font-medium',
          duration: 5000,
        }}
      />
      <Router>
        <Suspense>
          <Routes>
            <Route path='/login' element={<Login/>}/>
            <Route path='/register' element={<Register/>}/>
            <Route path='/reset-password' element={<ResetPassword/>}/>
            <Route path='/reset-password/:userID/:tokenID' element={<ConfirmResetPassword/>}/>
            <Route path='/verify' element={<Verify/>}/>
            <Route element={<PrivateRoute/>}>
              <Route path='/*' element={<Layout/>}/>
            </Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
