import { PaginationType } from "../../support/types";
import { Cancellation } from "../booking/types";
import { PayoutHistoryList } from "../withdrawal/types";

export const GET_REFUND_LIST = 'GET_REFUND_LIST'
export const GET_REFUND_MEMO = 'GET_REFUND_MEMO'
export const GET_REFUND_DETAIL = 'GET_REFUND_DETAIL'
export const GET_REFUND_HISTORY = 'GET_REFUND_HISTORY'
export const GET_REFUND_SUMMARIES = 'GET_REFUND_SUMMARIES'

export interface RefundTypes {
  _id: string;
  code: string;
  requested_time: string;
  status: string;
  amount: number;
  merchant: string;
  // name: string;
  // email: string;
  // account_number: string;
  // requested_by: string;
  // is_confirmed: boolean;
  // __v: number;
  // flip_account_status: boolean;
  // processed_time: string;
  // account_bank: string;
  // flip_payout_id: string;
  // finished_time: string;
}

export interface RefundListType extends PaginationType {
  docs: RefundTypes[];
}

export interface Refund {
  _id: string;
  name: string;
  alias: string;
  account_bank: string;
  account_number: string;
  amount: number;
  refund_amount: number;
  midtrans_fees: number;
  cancellation_fees: number;
}

export interface PayoutRefundDetail extends Refund {
  status: string;
}

export interface RefundSummariesTypes {
  refunds: Refund[];
  amount: number;
  total_before_flip: number;
  flip_fee: number;
  payout_code: string;
  total_transfer: number;
}

export interface GetRefundListType {
  type: typeof GET_REFUND_LIST;
  payload: RefundListType;
}

export interface GetRefundSummariesType {
  type: typeof GET_REFUND_SUMMARIES;
  payload: RefundSummariesTypes;
}

export interface GetRefundMemoType {
  type: typeof GET_REFUND_MEMO;
  payload: RefundTypes[];
}

export interface GetRefundDetailType {
  type: typeof GET_REFUND_DETAIL;
  payload: Cancellation | null;
}

export interface GetRefundHistoryListType {
  type: typeof GET_REFUND_HISTORY;
  payload: PayoutHistoryList
}

export interface RefundState {
  refundList: RefundListType;
  refundMemo: RefundTypes[];
  refundDetail: Cancellation | null;
  refundHistory: PayoutHistoryList;
  refundSummaries: RefundSummariesTypes | null;
}

export type RefundActionType =
  | GetRefundListType
  | GetRefundMemoType
  | GetRefundDetailType
  | GetRefundHistoryListType
  | GetRefundSummariesType