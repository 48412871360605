import {
  GET_ALL_PRODUCTS,
  GET_PRODUCT_ATTRIBUTES,
  GET_PRODUCT_DETAIL,
  GET_PRODUCT_LIST,
  ProductActionType,
  ProductState
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: ProductState = {
  allProducts: [],
  productList: defaultList,
  productDetail: null,
  productAttributes: []
}

export default function (state = initialState, action: ProductActionType): ProductState {
  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.payload
      }
    case GET_PRODUCT_LIST:
      return {
        ...state,
        productList: action.payload
      }
    case GET_PRODUCT_DETAIL:
      return {
        ...state,
        productDetail: action.payload
      }
    case GET_PRODUCT_ATTRIBUTES:
      return {
        ...state,
        productAttributes: action.payload
      }
    default:
      return state
  }
}