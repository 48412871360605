import { Icon, PaginationType } from "../../support/types";
import { CancellationPolicy } from "../booking/types";

export const GET_MERCHANT_DASHBOARD = 'GET_MERCHANT_DASHBOARD'
export const GET_MERCHANT_WITHDRAWAL_LIST = 'GET_MERCHANT_WITHDRAWAL_LIST'
export const GET_ACCOUNTING_DASHBOARD = 'GET_ACCOUNTING_DASHBOARD'
export const GET_ACCOUNTING_WITHDRAWAL_LIST = 'GET_ACCOUNTING_WITHDRAWAL_LIST'
export const GET_ACCOUNTING_MERCHANT_BALANCE = 'GET_ACCOUNTING_MERCHANT_BALANCE'
export const GET_ACCOUNTING_MERCHANT_BOOKING = 'GET_ACCOUNTING_MERCHANT_BOOKING'
export const GET_ACCOUNTING_PAYOUT_HISTORY = 'GET_ACCOUNTING_PAYOUT_HISTORY'
export const GET_WITHDRAWAL_DETAIL = 'GET_WITHDRAWAL_DETAIL'
export const GET_APPROVED_WITHDRAWAL = 'GET_APPROVED_WITHDRAWAL'
export const GET_PAYOUT_SUMMARIES = 'GET_PAYOUT_SUMMARIES'
export const CREATE_WITHDRAWAL_STEP_ONE = 'CREATE_WITHDRAWAL_STEP_ONE'

export interface MerchantDashboard {
  total_amount: number;
  withdrawable: number;
  total_balance: number;
  date: string | null;
  withdrawable_date: string | null;
}

export interface MerchantWithdrawalType {
  _id: string;
  startDate: string;
  endDate: string;
  approval_status: string;
  funds_status: string;
  total: number;
  fees: number;
  code: string;
  isWithdrawable: boolean;
  requestDate: string;
}

export interface MerchantWithdrawalList extends PaginationType {
  docs: MerchantWithdrawalType[];
}

export interface AccountingDashboard {
  date: string | null;
  total_commission: number;
  total_payment_gateway: number;
  total_payout_gateway: number;
  total_processed: number;
  total_pending_requests: number;
  total_remaining: number;
  total_incoming?: number;
  refund_requests?: number;
}

export interface AccountingWithdrawal {
  _id: string;
  startDate: string;
  endDate: string;
  approval_status: string;
  merchant: string;
  funds_status: string;
  total: number;
  fees: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  description: string;
  code: string;
  isEditable: boolean;
}

export interface AccountingWithdrawalList extends PaginationType {
  docs: AccountingWithdrawal[]
}

export interface WithdrawalCancellation {
  amount_refunded: number;
  cancelledBy: string;
  reason: string;
  cancellation_policy: CancellationPolicy;
  isRefundable: boolean;
  date: string;
  cancellation_fees: number;
}

export interface MerchantBooking {
  _id: string;
  fees: number;
  bookingNum: string;
  arrivalDate: string;
  totalAmount: number;
  status: string;
  finalAmount: number;
}

export interface WithdrawalBooking extends MerchantBooking{
  cancellation?: WithdrawalCancellation;
  percentage: string | null;
}

export interface Log {
  _id: string;
  action: string;
  actionBy: string;
  description: string;
  date: string;
  source: string;
}

export interface generalBankAccount {
  bank: string;
  name: string;
}

export interface General {
  _id: string;
  approval_status: string;
  funds_status: string;
  code: string;
  startDate: string;
  endDate: string;
  fees: number;
  total_balance: number;
  description: string;
  total_bookings: number;
  bank_account: generalBankAccount;
}

export interface WithdrawalDetail {
  general: General;
  logs: Log[];
  bookings: WithdrawalBooking[];
}

export interface MainCreateWithdrawal {
  bookings: WithdrawalBooking[];
  startDate: string | null;
  endDate: string | null;
  totalBookings: number;
  totalAmount: number;
}

export interface WithdrawalStepOne extends MainCreateWithdrawal {
  totalWithdrawal: number;
  initial_withdrawal: string;
}

export interface Balance {
  value: string | number;
  date: string;
}

export interface Contact {
  email: string;
  phone_number: string;
  whatsapp: string;
}

export interface MerchantDetail {
  _id: string;
  name: string;
  alias: string;
  icon: Icon;
  contact: Contact;
  health_level: string;
}

export interface MerchantBalance {
  _id: string;
  alias: string;
  merchant_detail: MerchantDetail;
  balance: Balance;
}

export interface MerchantBalanceList extends PaginationType {
  docs: MerchantBalance[]
}

export interface MerchantBookingList extends PaginationType {
  docs: MerchantBooking[]
}

export interface Account {
  account_number: string;
  bank_code: string;
}

export interface Payout {
  _id: string;
  code: string;
  withdrawals: string[];
  total: number;
  status: string;
  merchant: string;
  account: Account;
  idempotency_key: string;
  type: string;
  vendor: string;
  createdAt: string;
  updatedAt: string;
  kind: string;
  refund: string;
  __v: number;
  payout_id: string;
}

export interface PayoutHistoryTypes {
  _id: string;
  total: number;
  date: string;
  merchants: number;
  failed: number;
  queued: number;
  success: number;
  payouts: Payout[];
}

export interface PayoutHistoryList extends PaginationType {
  docs: PayoutHistoryTypes[];
}

export interface ApprovedWithdrawalList {
  _id: string;
  startDate: string;
  endDate: string;
  approval_status: string;
  merchant: string;
  funds_status: string;
  health_level: string;
  total: number;
  fees: number;
  net_fees: number;
  payout_fee: number;
  actual_net: number;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  __v: number;
  code: string;
}

export interface PayoutWithdrawal {
  _id: string;
  code: string;
  name: string;
  alias: string;
  requestDate: string;
  approval_status: string;
  funds_status: string;
  gross_hotel: number;
  willa_commission_fees: number;
  net_after_comission_and_midtrans: number;
  flip_fees: number;
  midtrans_fees: number;
  net_after_flip: number;
}

export interface PayoutWithdrawalDetail extends PayoutWithdrawal {
  status: string;
}

export interface PayoutSummaries {
  withdrawals: PayoutWithdrawal[];
  total_transfer: number;
  gross_amount: number;
  flip_fee: number;
  total_before_flip: number;
  merchants_alias: string[];
  percentage: number;
  payout_code: string;
  description: string;
  merchants: number;
}

export interface GetMerchantDashboardType {
  type: typeof GET_MERCHANT_DASHBOARD;
  payload:MerchantDashboard;
}

export interface GetAccountingDashboardType {
  type: typeof GET_ACCOUNTING_DASHBOARD;
  payload: AccountingDashboard;
}

export interface GetMerchantWithdrawalListType {
  type: typeof GET_MERCHANT_WITHDRAWAL_LIST;
  payload: MerchantWithdrawalList;
}

export interface GetAccountingWithdrawalListType {
  type: typeof GET_ACCOUNTING_WITHDRAWAL_LIST;
  payload: AccountingWithdrawalList;
}

export interface GetWithdrawalDetailType {
  type: typeof GET_WITHDRAWAL_DETAIL;
  payload: WithdrawalDetail;
}

export interface CreateWithdrawalStepOneType {
  type: typeof CREATE_WITHDRAWAL_STEP_ONE;
  payload: WithdrawalStepOne
}

export interface GetAccountingMerchantBalanceListType {
  type: typeof GET_ACCOUNTING_MERCHANT_BALANCE;
  payload: MerchantBalanceList
}

export interface GetAccountingMerchantBookingListType {
  type: typeof GET_ACCOUNTING_MERCHANT_BOOKING;
  payload: MerchantBookingList
}

export interface GetAccountingPayoutHistoryListType {
  type: typeof GET_ACCOUNTING_PAYOUT_HISTORY;
  payload: PayoutHistoryList
}

export interface GetApprovedWithdrawalListType {
  type: typeof GET_APPROVED_WITHDRAWAL;
  payload: ApprovedWithdrawalList[]
}

export interface GetPayoutSummariesType {
  type: typeof GET_PAYOUT_SUMMARIES;
  payload: PayoutSummaries | null
}

export interface WithdrawalState {
  merchantDashboard: MerchantDashboard;
  accountingDashboard: AccountingDashboard;
  merchantWithdrawal: MerchantWithdrawalList;
  accountingWithdrawal: AccountingWithdrawalList;
  withdrawalDetail: WithdrawalDetail | null;
  createWithdrawalStepOne: WithdrawalStepOne | null;
  accountingMerchantBalance: MerchantBalanceList;
  accountingMerchantBooking: MerchantBookingList;
  accountingPayoutHistory: PayoutHistoryList;
  approvedWithdrawal: ApprovedWithdrawalList[];
  payoutSummaries: PayoutSummaries | null
}

export type WithdrawalActionType =
  | GetMerchantDashboardType
  | GetAccountingDashboardType
  | GetMerchantWithdrawalListType
  | GetAccountingWithdrawalListType
  | GetWithdrawalDetailType
  | CreateWithdrawalStepOneType
  | GetAccountingMerchantBalanceListType
  | GetAccountingMerchantBookingListType
  | GetAccountingPayoutHistoryListType
  | GetApprovedWithdrawalListType
  | GetPayoutSummariesType