export const DATA_LOADING = 'DATA_LOADING'
export const DATA_LOADED = 'DATA_LOADED'
export const FETCH_LOADING = 'FETCH_LOADING'
export const FETCH_LOADED = 'FETCH_LOADED'
export const SET_CURRENT = 'SET_CURRENT'
export const SET_MESSAGE = 'SET_MESSAGE'
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE'
export const GET_ITEM_DEPENDENCY = 'GET_ITEM_DEPENDENCY'
export const REMOVE_ITEM_DEPENDENCY = 'REMOVE_ITEM_DEPENDENCY'
export const GET_STATUS = 'GET_STATUS'

export interface PaginationType {
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null | number,
  nextPage: null | number,
}

export interface DependenciesType {
  [depKeys: string]: number
}

export interface statusType {
  id: string;
  isSuccess: boolean;
  message: string;
}

export interface Icon {
  url: string;
  path: string;
  mimetype: string;
  md5: string;
}

export interface DataLoadingType {
  type: typeof DATA_LOADING
}

export interface DataLoadedType {
  type: typeof DATA_LOADED
}

export interface FetchLoadingType {
  type: typeof FETCH_LOADING
}

export interface FetchLoadedType {
  type: typeof FETCH_LOADED
}

export interface SetCurrentType {
  type: typeof SET_CURRENT
  payload: any
}

export interface SetMessageType {
  type: typeof SET_MESSAGE
  payload: string
}

export interface RemoveMessageType {
  type: typeof REMOVE_MESSAGE
  payload: string
}

export interface GetItemDependencyType {
  type: typeof GET_ITEM_DEPENDENCY
  payload: DependenciesType
}

export interface RemoveItemDependencyType {
  type: typeof REMOVE_ITEM_DEPENDENCY
  payload: null
}

export interface GetStatusTypes {
  type: typeof GET_STATUS
  payload: statusType
}

export interface SupportState {
  current: any | null
  isLoading: boolean
  fetchLoading: boolean
  message: string
  dependency: DependenciesType | null
  status: statusType
}

export type SupportActionType =
  | DataLoadingType
  | DataLoadedType
  | FetchLoadingType
  | FetchLoadedType
  | GetStatusTypes
  | SetCurrentType
  | SetMessageType
  | RemoveMessageType
  | GetItemDependencyType
  | RemoveItemDependencyType