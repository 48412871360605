import {
  AmenityActionType,
  AmenityState,
  GET_ALL_AMENITIES,
  GET_AMENITY_LIST
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const amenitiesType = [
  {
    ids: 'Bedroom',
    name: 'Bedroom Amenities',
    desc: 'The facilities listed above are the basic facilities contained in the bedroom to provide comfort to guests during their stay.'
  },
  {
    ids: 'Bathroom',
    name: 'Bathroom Amenities',
    desc: 'The facilities listed above are additional facilities related to self-care to make their accommodation and stay more satisfying.'
  },
  {
    ids: 'Outside',
    name: 'Outside Bedroom',
    desc: 'The facilities listed above are the facilities offered to guests from the hotel when they are not resting in their bedroom.'
  },
]

const initialState: AmenityState = {
  amenityList: defaultList,
  allAmenities: [],
  amenityTypes: amenitiesType
}

export default function (state= initialState, action: AmenityActionType): AmenityState {
  switch (action.type) {
    case GET_AMENITY_LIST:
      return {
        ...state,
        amenityList: action.payload
      }
    case GET_ALL_AMENITIES:
      return {
        ...state,
        allAmenities: action.payload
      }
    default:
      return state
  }
}