import { GET_STAFF_LIST, StaffActionType, StaffState } from "./types";
import { defaultList } from "../../../utils/helper";

const initialState: StaffState = {
  staffList: defaultList
}

export default function (state= initialState, action: StaffActionType): StaffState {
  switch (action.type) {
    case GET_STAFF_LIST:
      return {
        ...state,
        staffList: action.payload
      }
    default:
      return state
  }
}