import { image } from "../menu/product/types";

export const LOGIN_SUCCESS  = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS  = 'LOGOUT_SUCCESS'

export interface subMenusType {
  name: string,
  _id: string
}

export interface accessType {
  read: boolean,
  create: boolean,
  edit: boolean,
  delete: boolean,
  sub_menus?: subMenusType[]
}

export interface privilege {
  [menu: string] : accessType
}

export interface merchant {
  icon: image
  name: string
}

export interface LoginSuccessType {
  type: typeof LOGIN_SUCCESS
  payload: string
  merchant: merchant
  staffId: string
}

export interface LogoutSuccessType {
  type: typeof LOGOUT_SUCCESS
}

export interface AuthState {
  isAuth: boolean
  authToken: string | null
  merchantId: string | null
  staffId: string | null
  merchant: merchant
  role: string | null
  privilege: privilege | null
}

export type AuthActionType =
  | LoginSuccessType
  | LogoutSuccessType