import { PaginationType } from "../../support/types";

export const GET_ATTRIBUTE_LIST = 'GET_ATTRIBUTE_LIST'
export const GET_ALL_ATTRIBUTES = 'GET_ALL_ATTRIBUTES'

export interface image {
  url: string;
  path: string;
  _id: string;
}

export interface attribute {
  name: string;
  desc: string;
  description: string;
  images: image[];
  _id: string;
}

export interface attributeList extends PaginationType {
  docs: attribute[]
}

export interface GetAttributeListType {
  type: typeof GET_ATTRIBUTE_LIST,
  payload: attributeList
}

export interface GetAllAttributesType {
  type: typeof GET_ALL_ATTRIBUTES,
  payload: attribute[]
}

export interface AttributeState {
  attributeList: attributeList
  allAttributes: attribute[]
}

export type AttributeActionType =
  | GetAllAttributesType
  | GetAttributeListType
