import {
  GET_REFUND_DETAIL,
  GET_REFUND_HISTORY,
  GET_REFUND_LIST,
  GET_REFUND_MEMO,
  GET_REFUND_SUMMARIES,
  RefundActionType,
  RefundState
} from "./types";
import { defaultList } from "../../../utils/helper";

const initialState: RefundState = {
  refundList: defaultList,
  refundMemo: [],
  refundDetail: null,
  refundHistory: defaultList,
  refundSummaries: null
}

export default function (state= initialState, action: RefundActionType): RefundState {
  switch (action.type) {
    case GET_REFUND_LIST:
      return {
        ...state,
        refundList: action.payload
      }
    case GET_REFUND_MEMO:
      return {
        ...state,
        refundMemo: action.payload
      }
    case GET_REFUND_DETAIL:
      return {
        ...state,
        refundDetail: action.payload
      }
    case GET_REFUND_HISTORY:
      return {
        ...state,
        refundHistory: action.payload
      }
    case GET_REFUND_SUMMARIES:
      return {
        ...state,
        refundSummaries: action.payload
      }
    default:
      return state
  }
}