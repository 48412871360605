export const GET_ROLE_LIST = 'GET_ROLE_LIST';

export interface RoleListType {
  _id: string;
  role_name: string;
}

export interface GetRoleListType {
  type: typeof GET_ROLE_LIST;
  payload: RoleListType[]
}

export interface RoleState {
  roleList: RoleListType[]
}

export type RoleActionType =
  | GetRoleListType
