import { legacy_createStore as createStore, applyMiddleware, Action, AnyAction } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk, { ThunkAction } from "redux-thunk";
import combineReducers from './rootReducer'

declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const initialState = {}

const middleware = [thunk]

const store = createStore(combineReducers, initialState, composeWithDevTools(applyMiddleware(...middleware)))

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export default store