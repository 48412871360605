import { attribute } from "../attribute/types";
import { amenity } from "../amenity/types";
import { PaginationType } from "../../support/types";

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST'
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL'
export const GET_PRODUCT_ATTRIBUTES = 'GET_PRODUCT_ATTRIBUTES'

export interface size {
  value: number;
  unit: string;
}

export interface image {
  url: string;
  path: string;
  _id: string;
  mimetype: string;
  md5: string;
}

export interface mainValue {
  _id: string;
  name: string;
}

export interface product extends mainValue{
  size: size;
  product_type: string;
  description: string;
  status: string;
  amenities: string[];
  attributes: string[];
  maxAdults: number;
  maxChildren: number;
  maxPersons: number;
  images: image[]
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  order: number;
  __v: number;
}

export interface productList extends PaginationType{
  docs: product[]
}

export interface productDetail {
  general: product;
  attributes: attribute[];
  amenities: amenity[];
}

export interface Item {
  name: string;
  desc: string;
  _id: string;
  images: image[];
}

export interface productAttributes {
  _id: string;
  name: string;
  type: string;
  isRequired: boolean;
  isEditable: boolean;
  items: Item[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  description: string;
}

export interface GetAllProductsType {
  type: typeof GET_ALL_PRODUCTS
  payload: mainValue[]
}

export interface GetProductListType {
  type: typeof GET_PRODUCT_LIST
  payload: productList
}

export interface GetProductDetailType {
  type: typeof GET_PRODUCT_DETAIL
  payload: productDetail
}

export interface GetProductAttributesType {
  type: typeof GET_PRODUCT_ATTRIBUTES
  payload: productAttributes[]
}

export interface ProductState {
  allProducts: mainValue[]
  productList: productList
  productDetail: productDetail | null
  productAttributes: productAttributes[]
}

export type ProductActionType =
  | GetAllProductsType
  | GetProductDetailType
  | GetProductListType
  | GetProductAttributesType
