import {
  AuthActionType,
  AuthState,
  LOGIN_SUCCESS, LOGOUT_SUCCESS
} from "./types";
import { isBase64 } from "../../utils/helper";

const initialState: AuthState = {
  isAuth: !!localStorage.getItem('authToken') && isBase64(localStorage.getItem('privilege' || '{}')),
  authToken: localStorage.getItem('authToken'),
  merchantId: localStorage.getItem('merchId'),
  staffId: localStorage.getItem('staffId'),
  merchant: JSON.parse(localStorage.getItem('merchant') || '{}'),
  role: localStorage.getItem('role'),
  privilege: isBase64(localStorage.getItem('privilege' || '{}')),
}

export default function (state= initialState, action: AuthActionType): AuthState {
  switch (action.type) {
    case LOGIN_SUCCESS:
      const role = JSON.parse(window.atob(action.payload.split('.')[1])).role
      const privilege = JSON.parse(window.atob(action.payload.split('.')[1])).acl
      const merchId = JSON.parse(window.atob(action.payload.split('.')[1])).merchantId
      localStorage.setItem('authToken', action.payload)
      localStorage.setItem('role', role)
      localStorage.setItem('merchId', merchId)
      localStorage.setItem('merchant', JSON.stringify(action.merchant))
      localStorage.setItem('staffId', action.staffId)
      localStorage.setItem('privilege', privilege)
      return {
        ...state,
        isAuth: true,
        authToken: action.payload,
        merchantId: merchId,
        merchant: action.merchant,
        staffId: action.staffId,
        role: role,
        privilege: JSON.parse(window.atob(privilege))
      }
    case LOGOUT_SUCCESS:
      localStorage.clear()
      return {
        ...state,
        isAuth: false,
        authToken: null,
        merchantId: null,
        staffId: null,
        privilege: null
      }
    default:
      return state
  }
}