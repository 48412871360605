import { MainCreateWithdrawal, WithdrawalBooking } from "../withdrawal/types";
import { PaginationType } from "../../support/types";
import { Contact, MerchantList } from "../merchant/types";

export const GET_MERCHANT_INVOICE_DASHBOARD = 'GET_MERCHANT_INVOICE_DASHBOARD'
export const GET_WILLA_INVOICE_DASHBOARD = 'GET_WILLA_INVOICE_DASHBOARD'
export const GET_WILLA_INVOICE_LIST = 'GET_WILLA_INVOICE_LIST'
export const GET_MERCHANT_UNPAID_FUNDS = 'GET_MERCHANT_UNPAID_FUNDS'
export const GET_MERCHANT_INVOICE_LIST = 'GET_MERCHANT_INVOICE_LIST'
export const GET_INVOICE_DETAIL = 'GET_INVOICE_DETAIL'
export const CREATE_INVOICE_STEP_ONE = 'CREATE_INVOICE_STEP_ONE'
export const GET_MERCHANT_SUMMARY = 'GET_MERCHANT_SUMMARY'

export interface Pending {
  price: number;
  _id: string | null;
  pay_url: string | null;
  expiration_date: string | null;
}

export interface Funds {
  amount: number;
  date: string;
}

export interface MerchantInvoiceDashboard {
  health: string;
  last_paid: Funds;
  unpaid_funds: Funds;
  pending: Pending;
}

export interface WillaInvoiceDashboard {
  balance: Funds;
  potential_invoice: Funds;
  total_merchants: Funds;
  last_payout: number;
}

export interface UnpaidBooking extends WithdrawalBooking {
  merchant: string;
}

export interface UnpaidBookingList extends PaginationType {
  docs: UnpaidBooking[];
}

export interface Invoice {
  _id: string;
  startDate: string;
  endDate: string;
  total: number;
  createdAt: string;
  expiration_date: string;
  status: string;
  code: string;
}

export interface InvoiceList extends PaginationType {
  docs: Invoice[];
}

export interface General {
  _id: string;
  startDate: string;
  endDate: string;
  total: number;
  description: string;
  external_id: string;
  status: string;
  total_bookings: number;
}

export interface Log {
  _id: string;
  action: string;
  actionBy: string;
  description: string;
  date: string;
  source: string;
}

export interface PayInvoice {
  _id: string;
  expiration_date: string;
  invoice_url: string;
}

export interface InvoiceDetail {
  general: General;
  logs: Log[];
  pay_invoice: PayInvoice;
  paidAt: string;
  createdBy: string;
}

export interface InvoiceStepOne extends MainCreateWithdrawal {
  totalPayment: number;
  initial_invoice: string;
}

export interface MerchantDetail extends MerchantList{
  health_level: string;
  contact: Contact;
}

export interface Latest {
  startDate: string;
  endDate: string;
  total: number;
}

export interface WillaInvoice {
  _id: string;
  latest: Latest;
  merchant_detail: MerchantDetail;
}

export interface WillaInvoiceList extends PaginationType {
  docs: WillaInvoice[];
}

export interface UnpaidFunds {
  total: number;
  date: string;
}

export interface LastExpired {
  total: number;
  createdAt: string | null;
}

export interface LastCreated extends LastExpired {
  _id: string;
}

export interface InvoiceDetails {
  last_created: LastCreated;
  last_paid: LastCreated;
  last_expired: LastExpired;
  unpaid_funds: UnpaidFunds;
}

export interface Warning {
  count: number;
  last: string | null;
}

export interface Reversed extends Warning {
  reason: string;
}

export interface HealthStats {
  reversed: Reversed;
  dormant: Warning;
  warning: Warning;
}

export interface InvoiceRecap {
  expired: number;
  creation: number;
  paid: number;
  late_invoices: number;
}

export interface WithdrawalRecap {
  rejected: number;
  approved: number;
  requests: number;
  attempts: number;
  fail: number;
  success: number;
  reversed: number;
}

export interface Content {
  withdrawal: WithdrawalRecap;
  invoice: InvoiceRecap;
  health_stats: HealthStats;
  invoice_details: InvoiceDetails;
}

export interface MerchantSummary {
  _id: string;
  type: string;
  content: Content;
  merchant: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface GetMerchantInvoiceDashboard {
  type: typeof GET_MERCHANT_INVOICE_DASHBOARD;
  payload: MerchantInvoiceDashboard;
}

export interface GetWillaInvoiceDashboard {
  type: typeof GET_WILLA_INVOICE_DASHBOARD;
  payload: WillaInvoiceDashboard;
}

export interface GetWillaInvoiceList {
  type: typeof GET_WILLA_INVOICE_LIST;
  payload: WillaInvoiceList;
}

export interface GetMerchantUnpaidFunds {
  type: typeof GET_MERCHANT_UNPAID_FUNDS;
  payload: UnpaidBookingList | null;
}

export interface GetMerchantInvoiceList {
  type: typeof GET_MERCHANT_INVOICE_LIST;
  payload: InvoiceList;
}

export interface GetInvoiceDetail {
  type: typeof GET_INVOICE_DETAIL;
  payload: InvoiceDetail | null;
}

export interface CreateInvoiceStepOne {
  type: typeof CREATE_INVOICE_STEP_ONE;
  payload: InvoiceStepOne | null;
}

export interface GetMerchantSummary {
  type: typeof GET_MERCHANT_SUMMARY;
  payload: MerchantSummary | null;
}

export interface InvoiceState {
  merchantInvoiceDashboard: MerchantInvoiceDashboard;
  merchantUnpaidFunds: UnpaidBookingList | null;
  merchantInvoiceList: InvoiceList;
  invoiceDetail: InvoiceDetail | null;
  invoiceStepOne: InvoiceStepOne | null;
  willaInvoiceDashboard: WillaInvoiceDashboard;
  willaInvoiceList: WillaInvoiceList;
  merchantSummary: MerchantSummary | null;
}

export type InvoiceActionType =
  | GetMerchantInvoiceDashboard
  | GetMerchantUnpaidFunds
  | GetMerchantInvoiceList
  | GetInvoiceDetail
  | CreateInvoiceStepOne
  | GetWillaInvoiceDashboard
  | GetWillaInvoiceList
  | GetMerchantSummary