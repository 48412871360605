import { GET_ALL_RATE_PLANS, GET_RATE_PLAN_LIST, RATE_PLAN_GALLERY, RatePlanActionType, RatePlanState } from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const defaultRatePlanDeps = {
  allotments: 0,
  bookings: 0
}

const initialState: RatePlanState = {
  allRatePlans: [],
  ratePlanList: defaultList,
  ratePlanGallery: []
}

export default function (state = initialState, action: RatePlanActionType): RatePlanState {
  switch (action.type) {
    case GET_ALL_RATE_PLANS:
      return {
        ...state,
        allRatePlans: action.payload
      }
    case GET_RATE_PLAN_LIST:
      return {
        ...state,
        ratePlanList: action.payload
      }
    case RATE_PLAN_GALLERY:
      return {
        ...state,
        ratePlanGallery: action.payload
      }
    default:
      return state
  }
}