import { attribute } from "../attribute/types";
import { PaginationType } from "../../support/types";
import { image } from "../product/types";

export const GET_ALL_RATE_PLANS = 'GET_ALL_RATE_PLANS'
export const GET_RATE_PLAN_LIST = 'GET_RATE_PLAN_LIST'
export const RATE_PLAN_GALLERY = 'RATE_PLAN_GALLERY'

export interface Product {
  _id: string;
  name: string;
}

export interface Attributes {
  attribute_live: string;
  name: string;
  isRequired: boolean;
  isEditable: boolean;
  type: string;
  items: attribute[];
  _id: string;
}

export interface Policy {
  pivot: number;
  before: number;
  after: number;
}

export interface ratePlan {
  _id: string;
  name: string;
  description: string;
  product: Product;
  attributes: Attributes[];
  hex_color: string;
  status: string;
  gallery: image[];
  createdAt: string;
  createdBy: string;
  policy: Policy;
  isRefundable: boolean;
}

export interface allRatePlan {
  _id: string;
  name: string;
  description: string;
  product: string;
  attributes: Attributes[];
  hex_color: string;
  status: string;
  gallery: image[];
  createdAt: string;
  createdBy: string;
}

export interface ratePlanList extends PaginationType {
  docs: ratePlan[];
}

export interface ratePlanGallery {
  name: string;
  images: image[];
}

export interface GetAllRatePlansType {
  type: typeof GET_ALL_RATE_PLANS
  payload: allRatePlan[]
}

export interface GetRatePlanListType {
  type: typeof GET_RATE_PLAN_LIST
  payload: ratePlanList
}

export interface RatePlanGalleryType {
  type: typeof RATE_PLAN_GALLERY
  payload: ratePlanGallery[]
}

export interface RatePlanState {
  allRatePlans: allRatePlan[],
  ratePlanList: ratePlanList,
  ratePlanGallery: ratePlanGallery[]
}

export type RatePlanActionType =
  | GetAllRatePlansType
  | GetRatePlanListType
  | RatePlanGalleryType