import {
  AdditionalState,
  AdditionalActionType,
  GET_ADDITIONAL_LIST,
  GET_ALL_ADDITIONALS
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: AdditionalState = {
  allAdditionals: [],
  additionalList: defaultList
}

export default function (state = initialState, action: AdditionalActionType): AdditionalState {
  switch (action.type) {
    case GET_ALL_ADDITIONALS:
      return {
        ...state,
        allAdditionals: action.payload
      }
    case GET_ADDITIONAL_LIST:
      return {
        ...state,
        additionalList: action.payload
      }
    default:
      return state
  }
}