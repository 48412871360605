import {
  DashboardActionType,
  DashboardState,
  GET_MERCHANT_GRAPH,
  GET_MERCHANT_SUMMARY,
  GET_WILLA_SUMMARY
} from "./types";

const initialState: DashboardState = {
  merchantSummary: {
    booking: {
      arrival: 0,
      departure: 0,
      rented: 0,
      high_selling: [],
      least_selling: []
    },
    revenue: {
      date: '',
      total_amount: 0,
      total_balance: 0,
      withdrawable: 0,
      withdrawable_date: ''
    },
    health_status: '',
    merchant_health: {
      dormant: { count: 0, last: null },
      warning: { count: 0, last: null },
      reversed: { count: 0, last: null, reason: '' }
    }
  },
  merchantGraph: [],
  willaSummary: {
    booking: { arrival: 0, departure: 0, rented: 0 },
    health_status: { dormant: 0, healthy: 0, warning: 0 },
    revenue: { balance: 0, last_payout: 0, potential_invoice: 0 }
  }
}

export default function (state= initialState, action: DashboardActionType): DashboardState {
  switch (action.type) {
    case GET_MERCHANT_SUMMARY:
      return {
        ...state,
        merchantSummary: action.payload
      }
    case GET_MERCHANT_GRAPH:
      return {
        ...state,
        merchantGraph: action.payload
      }
    case GET_WILLA_SUMMARY:
      return {
        ...state,
        willaSummary: action.payload
      }
    default:
      return state
  }
}