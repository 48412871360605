import { PaginationType } from "../../support/types";

export const GET_ADDITIONAL_LIST = 'GET_ADDITIONAL_LIST'
export const GET_ALL_ADDITIONALS = 'GET_ALL_ADDITIONALS'

export interface additional {
  _id: string;
  name: string;
  desc: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface additionalList extends PaginationType {
  docs: additional[]
}

export interface GetAdditionalListType {
  type: typeof GET_ADDITIONAL_LIST
  payload: additionalList
}

export interface GetAllAdditonalsType {
  type: typeof GET_ALL_ADDITIONALS
  payload: additional[]
}

export interface AdditionalState {
  additionalList: additionalList,
  allAdditionals: additional[]
}

export type AdditionalActionType =
  | GetAdditionalListType
  | GetAllAdditonalsType
