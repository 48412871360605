import { PaginationType } from "../../support/types";

export const GET_AMENITY_LIST = 'GET_AMENITY_LIST'
export const GET_ALL_AMENITIES = 'GET_ALL_AMENITIES'
export const ADD_AMENITY = 'ADD_AMENITY'
export const EDIT_AMENITY = 'EDIT_AMENITY'
export const DELETE_AMENITY = 'DELETE_AMENITY'

export interface icon {
  md5: string;
  mimetype: string
  url: string;
  path: string;
}

export interface amenity {
  _id: string;
  name: string;
  icon: icon[];
  desc: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}

export interface amenityTypes {
  ids: string;
  name: string;
  desc: string;
}

export interface amenityList extends PaginationType {
  docs: amenity[]
}

export interface GetAmenityListType {
  type: typeof GET_AMENITY_LIST
  payload: amenityList
}

export interface GetAllAmenitiesType {
  type: typeof GET_ALL_AMENITIES
  payload: amenity[]
}

export interface AddAmenityListType {
  type: typeof ADD_AMENITY
  payload: amenity
}

export interface EditAmenityListType {
  type: typeof EDIT_AMENITY
  payload: amenity
}

export interface AmenityState {
  amenityList: amenityList
  allAmenities: amenity[]
  amenityTypes: amenityTypes[]
}

export type AmenityActionType =
  | GetAllAmenitiesType
  | GetAmenityListType
  | AddAmenityListType
  | EditAmenityListType