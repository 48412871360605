import {
  GET_BANK_LIST,
  GET_MERCHANT_BANK_ACCOUNT,
  GET_MERCHANT_LIST,
  GET_MERCHANT_PROFILE,
  MerchantActionType,
  MerchantState,
  Profile
} from "./types";

const profileDefaultValue: Profile = {
  title: '',
  desc: '',
  tagline: '',
  hero: null,
  logo: null,
  total_rooms: 0,
  rating_gmaps: 0,
  others: {},
  location: '',
  location_url: '',
  contact: {
    email: '',
    phone_number: ''
  },
  socials: {},
  website_url: '',
  booking_engine_url: '',
  bank_accounts: [],
  privacy_policy: { url: '' },
  terms_conditions: null,
  email_notifications: ''
}

const initialState: MerchantState = {
  profile: profileDefaultValue,
  merchantList: [],
  merchantBankAccount: [],
  bankList: []
}

export default function (state= initialState, action: MerchantActionType): MerchantState {
  switch (action.type) {
    case GET_MERCHANT_PROFILE:
      return {
        ...state,
        profile: action.payload
      }
    case GET_MERCHANT_LIST:
      return {
        ...state,
        merchantList: action.payload
      }
    case GET_MERCHANT_BANK_ACCOUNT:
      return {
        ...state,
        merchantBankAccount: action.payload
      }
    case GET_BANK_LIST:
      return {
        ...state,
        bankList: action.payload
      }
    default:
      return state
  }
}