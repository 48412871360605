import {
  GET_LANDING_ASSET,
  GET_LANDING_MENU,
  GET_LANDING_OFFERING,
  LandingPageActionType,
  LandingPageState,
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: LandingPageState = {
  menus: [],
  explore: [],
  offering: defaultList,
  asset: {}
}

export default function (state= initialState, action: LandingPageActionType): LandingPageState {
  switch (action.type) {
    case GET_LANDING_MENU:
      return {
        ...state,
        menus: action.payload
      }
    case GET_LANDING_OFFERING:
      return {
        ...state,
        offering: action.payload
      }
    case GET_LANDING_ASSET:
      return {
        ...state,
        asset: action.payload
      }
    default:
      return state
  }
}