import { PaginationType } from "../../support/types";
import { Hero } from "../landingPage/types";

export const GET_BOOKING_LIST = 'GET_BOOKING_LIST'
export const GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL'
export const GET_AVAILABLE_ALLOTMENT = 'GET_AVAILABLE_ALLOTMENT'
export const GET_MESSAGE_LISTENER = 'GET_MESSAGE_LISTENER'
export const BOOKING_DATA_LOADING = 'BOOKING_DATA_LOADING'
export const BOOKING_DATA_LOADED = 'BOOKING_DATA_LOADED'

export interface Booker {
  title: string;
  name: string;
  email: string;
  phone_number: string;
  verified: boolean;
}

export interface PaymentOptions {
  _id: string;
  name: string;
  code: string;
  instruction: string | null;
}

export interface Booking {
  _id: string;
  nights: number;
  arrivalDate: string;
  departureDate: string;
  createdBy: string;
  booker: Booker;
  bookingNum: string;
  bookingDate: string;
  total_items: number;
  status: string;
  isEditable:  boolean
  payment_options: PaymentOptions[] | null
}

export interface BookingList extends PaginationType {
  docs: Booking[];
}

export interface SpecialRequests {
  bed: string;
  floor: string,
  checkin: string;
  checkout: string;
  others: string[];
  notes: string;
}

export interface General {
  _id: string;
  arrivalDate: string;
  departureDate: string;
  createdBy: string;
  status: string;
  bookingDate: string;
  failedBy: string | null;
  guestName: string;
  isEditable: boolean;
  specialRequests: SpecialRequests | null;
}

export interface Extra {
  name: string;
  qty: number;
  subtotal: number;
}

export interface Item {
  room: string;
  extras: Extra[];
  total_guest: string;
  nightOfStay: string;
  qty: number;
  attributes: string[];
  rate_plan: string;
  cancellation_policy: string[];
  isRefundable: boolean;
}

export interface Guest2 {
  title: string;
  name: string;
  phone_number: string;
  email: string;
}

export interface ContactInfo {
  title: string;
  name: string;
  email: string;
  phone_number: string;
}

export interface Guest {
  guest: Guest2;
  contact_info: ContactInfo;
}

export interface Icon {
  url: string;
  path: string;
}

export interface Log {
  action: string
  date: string
}

export interface RefundLogs {
  logs: Log[]
  next_process: string
}

export interface Payment {
  bookingNum: string;
  totalItems: string[];
  payment_status: string;
  total_payment: number;
  subtotal: number;
  payment_method: string;
  option: string;
  icon: Icon;
  proof: Hero[] | null;
  refund_logs: RefundLogs | null;
}

export interface CancellationPolicy {
  refund_percentage: number | null
  cancellation_percentage: number | null;
  match: string;
}

export interface MemoTypes {
  source_file: string;
  token: string;
  url: string;
  path: string;
  size: number;
}

export interface MemosTypes {
  pov: string;
  location: Icon;
}

export interface Cancellation {
  add_memo?: boolean;
  memo?: MemoTypes;
  memos?: MemosTypes[];
  guest: string;
  email: string;
  bookingNum: string;
  cancellation_policy: CancellationPolicy;
  totalAmount: number;
  cancellation_fees: number | null;
  total_refund: number | null;
  isRefundable: boolean;
  status: string;
  type: string;
  midtrans_fee?: number;
  willa_fee?: number;
  flip_fee?: number;
  service_fee?: number;
  fees?: number;
  memo_by?: string;
  memo_time?: string;
  processed_time?: string;
  processed_by?: string;
  finished_time?: string;
  commission_fees?: number;
  subtotal?: number;
  remaining_balance?: number;
}

export interface BookingDetail {
  general: General;
  items: Item[];
  guest: Guest;
  payment: Payment;
  cancellation: Cancellation | null
}

export interface CheckAvailability {
  startDate: string;
  endDate: string;
  roomCount: number;
  adults: number;
  children: number;
}

export interface AvailableRatePlan {
  _id: string;
  name: string;
  description: string;
  attributes: string[];
  bed: string[];
  price: number;
  qty: number;
  isRefundable: boolean;
  cancellation_policy: string;
}

export interface AvailableRoom {
  name: string;
  description: string;
  nights: number;
}

export interface listenerType {
  id: string;
  message: string;
}

export interface AvailableAllotment {
  room: AvailableRoom;
  rate_plans: AvailableRatePlan[];
}

export interface GetBookingListType {
  type: typeof GET_BOOKING_LIST
  payload: BookingList
}

export interface GetBookingDetailType {
  type: typeof GET_BOOKING_DETAIL
  payload: BookingDetail
}

export interface GetAvailableAllotmentType {
  type: typeof GET_AVAILABLE_ALLOTMENT
  payload: AvailableAllotment[]
}

export interface GetMessageListenerTypes {
  type: typeof GET_MESSAGE_LISTENER
  payload: listenerType
}

export interface BookingDataLoadingType {
  type: typeof BOOKING_DATA_LOADING
}

export interface BookingDataLoadedType {
  type: typeof BOOKING_DATA_LOADED
}

export interface BookingState {
  bookingList: BookingList
  bookingDetail: BookingDetail | null
  availableAllotment: AvailableAllotment[]
  messageListener: listenerType
  isLoading: boolean
}

export type BookingActionType =
  | GetBookingListType
  | GetBookingDetailType
  | GetAvailableAllotmentType
  | GetMessageListenerTypes
  | BookingDataLoadingType
  | BookingDataLoadedType