import {
  AttributeActionType,
  AttributeState,
  GET_ALL_ATTRIBUTES,
  GET_ATTRIBUTE_LIST
} from "./types";

const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}

const initialState: AttributeState = {
  attributeList: defaultList,
  allAttributes: []
}

export default function (state= initialState, action: AttributeActionType): AttributeState {
  switch (action.type) {
    case GET_ALL_ATTRIBUTES:
      return {
        ...state,
        allAttributes: action.payload
      }
    case GET_ATTRIBUTE_LIST:
      return {
        ...state,
        attributeList: action.payload
      }
    default:
      return state
  }
}