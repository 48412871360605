import { HealthStats } from "../invoice/types";

export const GET_WILLA_SUMMARY = 'GET_WILLA_SUMMARY'
export const GET_MERCHANT_SUMMARY = 'GET_MERCHANT_SUMMARY'
export const GET_MERCHANT_GRAPH = 'GET_MERCHANT_GRAPH'

export interface Selling {
  count: number;
  min_price: number;
  total_price: number;
  rate_plan: string;
  color: string;
}

export interface Booking {
  arrival: number;
  departure: number;
  rented: number;
}

export interface BookingSummary extends Booking {
  high_selling: Selling[];
  least_selling: Selling[];
}

export interface RevenueSummary {
  total_amount: number;
  withdrawable: number;
  total_balance: number;
  date: string;
  withdrawable_date: string;
}

export interface MerchantDashboard {
  booking: BookingSummary;
  revenue: RevenueSummary;
  health_status: string;
  merchant_health: HealthStats;
}

export interface HealthStatus {
  dormant: number;
  healthy: number;
  warning: number;
}

export interface Revenue {
  balance: number;
  last_payout: number;
  potential_invoice: number;
}

export interface WillaDashboard {
  booking: Booking;
  revenue: Revenue;
  health_status: HealthStatus;
}

export interface MerchantGraph {
  bookings: number;
  _id: string;
}

export interface GetMerchantDashboardType {
  type: typeof GET_MERCHANT_SUMMARY;
  payload: MerchantDashboard;
}

export interface GetWillaDashboardType {
  type: typeof GET_WILLA_SUMMARY;
  payload: WillaDashboard;
}

export interface GetMerchantGraphType {
  type: typeof GET_MERCHANT_GRAPH;
  payload: MerchantGraph[];
}

export interface DashboardState {
  willaSummary: WillaDashboard;
  merchantSummary: MerchantDashboard;
  merchantGraph: MerchantGraph[];
}

export type DashboardActionType =
  | GetMerchantDashboardType
  | GetWillaDashboardType
  | GetMerchantGraphType