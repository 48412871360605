import { image } from "../product/types";
import { PaginationType } from "../../support/types";

export const GET_LANDING_MENU = 'GET_LANDING_MENU'
export const GET_LANDING_OFFERING = 'GET_LANDING_OFFERING'
export const GET_LANDING_ASSET = 'GET_LANDING_ASSET'

export interface Hero {
  url: string
  path: string
  mimetype: string
  md5: string
}

export interface Package {
  title: string;
  images: image[];
  price: number;
  promo: number | null;
  description: string;
  status: string;
  createdBy: string;
  _id: string;
}

export interface Headline {
  text: string
  text_animations: string[]
}

export interface Menu {
  title: string
  tagline: string
  hero: Hero[] | null
  content: Hero
  url: string
  slug: string
  desc: string
  isContentStatic: boolean
  heroVisibleMain: boolean
  packages: Package[];
  isPackageEditable: boolean
  headline: Headline
}

export interface Explore {
  title: string
  url: string
  content: string
  desc: string
  hero: Hero | null
}

export interface Offering {
  _id: string;
  name: string;
  status: string;
  packages: Package[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  description: string;
}

export interface OfferingList extends PaginationType {
  docs: Offering[]
}

export interface Asset {
  [name: string]: Hero[]
}

export interface GetLandingMenu {
  type: typeof GET_LANDING_MENU
  payload: Menu[]
}

export interface GetLandingOffering {
  type: typeof GET_LANDING_OFFERING
  payload: OfferingList
}

export interface GetLandingAsset {
  type: typeof GET_LANDING_ASSET
  payload: Asset
}

export interface LandingPageState {
  menus: Menu[],
  explore: Explore[],
  offering: OfferingList,
  asset: Asset
}

export type LandingPageActionType =
  | GetLandingMenu
  | GetLandingOffering
  | GetLandingAsset