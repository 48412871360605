import { GET_ROLE_LIST, RoleActionType, RoleState } from "./types";

const initialState: RoleState = {
  roleList: []
}

export default function (state= initialState, action: RoleActionType): RoleState {
  switch (action.type) {
    case GET_ROLE_LIST:
      return {
        ...state,
        roleList: action.payload
      }
    default:
      return state
  }
}