import { PaginationType } from "../../support/types";

export const GET_STAFF_LIST = 'GET_STAFF_LIST';

export interface StaffType {
  _id: string;
  fullName: string;
  email_address: string;
  status: string;
  createdAt: string;
  verified: boolean;
  role: string;
  merchant: string;
  phone_number: string;
}

export interface StaffListType extends PaginationType {
  docs: StaffType[]
}

export interface GetStaffListType {
  type: typeof GET_STAFF_LIST;
  payload: StaffListType
}

export interface StaffState {
  staffList: StaffListType
}

export type StaffActionType =
  | GetStaffListType
