import {
  AllotmentActionType,
  AllotmentState,
  GET_ALL_ALLOTMENTS,
  GET_ALLOTMENT_DETAILS,
  GET_GROUPED_ALLOTMENTS
} from "./types";

const initialState: AllotmentState = {
  allAllotments: [],
  allotmentDetails: [],
  groupedAllotments: []
}

export default function (state= initialState, action: AllotmentActionType): AllotmentState {
  switch (action.type) {
    case GET_ALL_ALLOTMENTS:
      return {
        ...state,
        allAllotments: action.payload
      }
    case GET_ALLOTMENT_DETAILS:
      return {
        ...state,
        allotmentDetails: action.payload
      }
    case GET_GROUPED_ALLOTMENTS:
      return {
        ...state,
        groupedAllotments: action.payload
      }
    default:
      return state
  }
}