import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { Provider } from "react-redux";
import store from "./redux/store";
import 'material-symbols/outlined.css';
import 'material-symbols/rounded.css';

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <Provider store={store}>
      <App/>
  </Provider>
)

reportWebVitals();
