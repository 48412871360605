export const IDRFormat = (x: number) => new Intl.NumberFormat("id-ID", {style: "currency", currency: "IDR", maximumFractionDigits: 0}).format(x);

// capitalize string onto capitalize for each letters
export const capitalizeFirstLetter = (oldString: string) => {
  const temp = oldString.toLowerCase()
  const words = temp.split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ')
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export const dynamicAssetFormatter = (title: string) => {
  const words = title.split('_')
  words.shift()
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].slice(1);
  }
  return words.join(' ')
}

export function isBase64(str: string | null) {
  if (str) {
    if (str === '' || str.trim() === '') {
      return null;
    }
    try {
      if (window.btoa(window.atob(str)) === str) {
        return JSON.parse(window.atob(str)!)
      }
    } catch (err) {
      localStorage.clear()
      return null;
    }
  } else {
    return null;
  }
}

export const fileSizeFormatter = (size: number) => {
  const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return parseInt((size / Math.pow(1024, i)).toFixed(2)) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}

export const defaultList = {
  docs: [],
  totalDocs: 0,
  limit: 7,
  page: 1,
  totalPages: 1,
  pagingCounter: 1,
  hasPrevPage: false,
  hasNextPage: false,
  prevPage: null,
  nextPage: null
}