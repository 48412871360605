import {
  CREATE_INVOICE_STEP_ONE,
  GET_INVOICE_DETAIL,
  GET_MERCHANT_INVOICE_DASHBOARD,
  GET_MERCHANT_INVOICE_LIST, GET_MERCHANT_SUMMARY,
  GET_MERCHANT_UNPAID_FUNDS,
  GET_WILLA_INVOICE_DASHBOARD,
  GET_WILLA_INVOICE_LIST,
  InvoiceActionType,
  InvoiceState
} from "./types";
import { defaultList } from "../../../utils/helper";

const initialState: InvoiceState = {
  merchantInvoiceDashboard: {
    health: '',
    pending: {
      price: 0,
      _id: null,
      expiration_date: null,
      pay_url: null
    },
    last_paid: {
      date: '',
      amount: 0
    },
    unpaid_funds: {
      date: '',
      amount: 0
    }
  },
  merchantUnpaidFunds: null,
  merchantInvoiceList: defaultList,
  invoiceDetail: null,
  invoiceStepOne: null,
  willaInvoiceDashboard: {
    balance: {
      date: '',
      amount: 0
    },
    potential_invoice: {
      date: '',
      amount: 0
    },
    total_merchants: {
      date: '',
      amount: 0
    },
    last_payout: 0
  },
  willaInvoiceList: defaultList,
  merchantSummary: null
}

export default function (state= initialState, action: InvoiceActionType): InvoiceState {
  switch (action.type) {
    case GET_MERCHANT_INVOICE_DASHBOARD:
      return {
        ...state,
        merchantInvoiceDashboard: action.payload
      }
    case GET_MERCHANT_UNPAID_FUNDS:
      return {
        ...state,
        merchantUnpaidFunds: action.payload
      }
    case GET_MERCHANT_INVOICE_LIST:
      return {
        ...state,
        merchantInvoiceList: action.payload
      }
    case GET_INVOICE_DETAIL:
      return {
        ...state,
        invoiceDetail: action.payload
      }
    case CREATE_INVOICE_STEP_ONE:
      return {
        ...state,
        invoiceStepOne: action.payload
      }
    case GET_WILLA_INVOICE_DASHBOARD:
      return {
        ...state,
        willaInvoiceDashboard: action.payload
      }
    case GET_WILLA_INVOICE_LIST:
      return {
        ...state,
        willaInvoiceList: action.payload
      }
    case GET_MERCHANT_SUMMARY:
      return {
        ...state,
        merchantSummary: action.payload
      }
    default:
      return state
  }
}